html {
    width:100%;
    height:100%;
    -webkit-text-size-adjust: none
}

body {
    position: fixed;
    margin: 0;
    padding: 0;
    width:100%;
    height:100%;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    overflow: hidden;
}

/*   
html {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: calc(100%) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: #000;
  -webkit-text-size-adjust: none
}
body {
  position:fixed;
  overflow:hidden;
  height: calc(100%) !important;
  width: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
} */
